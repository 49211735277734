<template>
  <div>

    <b-row>
      <b-col>
        <b-card class="header-marcar_consulta">
          <b-row align-v="center">
            <b-col>
              <h1 class="mb-2">
              <feather-icon icon="MapPinIcon" size="30" class="mr-50" />
              {{ id ? 'Editar' : 'Adicionar' }} Instituição
              </h1>
              <!-- <h4 class="mt-1">Selecione um paciente:</h4> -->
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    
    <b-card>
      <h4 class="mb-2">Dados da Instituição</h4>
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form
          class="chat-app-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row align-v="start">
            <b-col md="6">
              <b-form-group label="Nome da Empresa">
                <validation-provider
                  #default="{ errors }"
                  name="Primeiro Nome"
                  vid="firstName"
                  rules="required"
                >
                  <b-form-input
                    v-model="form.physicalPerson.firstName"
                    placeholder="Digite aqui"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            

           
            <!-- TODO - Colocar campo de calendário -->

            

            <b-col md="4">
              <b-form-group label="País">
                <b-form-input
                  v-model="form.nationality"
                  placeholder="Digite aqui"
                />
              </b-form-group>
            </b-col>
            

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="CNPJ"
                vid="cnpj"
                rules="required"
              >
                <b-form-group label="CNPJ">
                  <b-form-input
                    v-model="form.physicalPerson.cpf"
                    placeholder="Digite aqui"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- TODO - Qual é o campo de cns? -->
            

            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                name="Endereço"
                vid="address"
                rules="required"
              >
                <b-form-group label="Endereço">
                  <b-form-input
                    v-model="form.physicalPerson.person.address"
                    placeholder="Rua, Avenida, número"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="CEP"
                vid="postalCode"
                rules="required"
              >
                <b-form-group>
                  <b-form-input
                    v-model="form.physicalPerson.person.postalCode"
                    placeholder="CEP"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="Bairro"
                vid="neighborhood"
                rules="required"
              >
                <b-form-group>
                  <b-form-input
                    v-model="form.physicalPerson.person.neighborhood"
                    placeholder="Bairro"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="Cidade"
                vid="city"
                rules="required"
              >
                <b-form-group>
                  <b-form-input
                    v-model="form.physicalPerson.person.city"
                    placeholder="Cidade"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Estado"
                vid="state"
                rules="required"
              >
                <b-form-group label="Estado">
                  <b-form-input
                    v-model="form.physicalPerson.person.state"
                    placeholder="Digite aqui"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- TODO - Fazer o get de escolaridades -->
            

            
            
            <b-col md="5">
              <b-form-group label="Contato">
                <b-form-input
                  v-model="form.physicalPerson.person.phoneNumber"
                  placeholder="Fixo"
                />
              </b-form-group>
            </b-col>

            <b-col md="5">
              <b-form-group  label="Celular">
                
                <b-form-input
                  v-model="form.physicalPerson.person.mobilePhone"
                  placeholder="Celular"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Email">
                <b-form-input
                  v-model="form.physicalPerson.person.email"
                  type="email"
                  placeholder="exemplo@exemplo.com"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card>
      <h4 class="mb-2">Licença na plataforma</h4>

      <b-form class="chat-app-form">
        <b-row align-v="start">
          <b-col md="3">
            <b-form-checkbox
              v-model="form.visitedPsy"
              name="check-button"
              switch
              inline
            >
              Licença ativa
            </b-form-checkbox>
          </b-col>
          
        </b-row>
        
      </b-form>
    </b-card>
    <b-card>
      <h4 class="mb-2">Alterar Senha</h4>

      <b-form>
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group
            label="Senha Antiga"
            label-for="account-old-password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-old-password"
                v-model="passwordValueOld"
                name="old-password"
                :type="passwordFieldTypeOld"
                placeholder="Senha Antiga"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconOld"
                  class="cursor-pointer"
                  @click="togglePasswordOld"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group
            label-for="account-new-password"
            label="Nova Senha"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="newPasswordValue"
                :type="passwordFieldTypeNew"
                name="new-password"
                placeholder="Nova Senha"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
            label-for="account-retype-new-password"
            label="Confirmar Nova Senha"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-retype-new-password"
                v-model="RetypePassword"
                :type="passwordFieldTypeRetype"
                name="retype-password"
                placeholder="Confirmar Nova Senha"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
          >
            Salvar Alterações
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mt-1"
          >
            Redefinir
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
    </b-card>
   
  </div>
</template>

<script>
import schoolingService from '@/services/schoolingService'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

import {
  BCard,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BCardText,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import patientService from '@/services/patientService'
import maritalService from '@/services/maritalService'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import DependentesFormModal from '@/components/modals/DependentesFormModal.vue'
import { cloneObject } from '@/@core/utils/utils'
import { hideSpinner, showSpinner } from '@/services/spinService'

export default {
  components: {
    DependentesFormModal,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BCard,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    BCardText,
    BFormTextarea,

    BFormCheckbox,
    flatPickr,
  },
  props: ['id'],
  data: () => ({
    showDependentesModal: true,
    required,
    sexos: ['Masculino', 'Feminino', 'Outros'],
    schoolingOptions: [],
    maritalStatus: [],
    genderOptions: [
      { name: 'Masculino', id: 'M' },
      { name: 'Feminino', id: 'F' },
    ],
    meses: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    form: {
      age: 0,
      birthDate: undefined,
      createdBy: undefined,
      dependents: undefined,
      gender: undefined,
      internment: true,
      maritalStatus: undefined,
      nationality: undefined,
      note: undefined,
      occupation: undefined,
      outpatient: true,
      parentNote: undefined,
      parents: undefined,
      physicalPerson: {
        cpf: undefined,
        createdBy: undefined,
        firstName: undefined,
        lastName: undefined,
        professionalBoardNumber: '123456',
        person: {
          address: undefined,
          city: undefined,
          createdBy: undefined,
          email: undefined,
          login: undefined,
          mobilePhone: undefined,
          neighborhood: undefined,
          phoneNumber: undefined,
          postalCode: undefined,
          state: undefined,
          status: undefined,
          updatedBy: undefined,
        },
        updatedBy: undefined,
      },
      schooling: undefined,
      updatedBy: undefined,
      visitedPsy: true,
    },
  }),
  methods: {
    prepareRequest(obj) {
      return {
        ...obj,
        gender: obj.gender.id,
        birthDate: `${obj.birthDate.split('/').reverse().join('-')} 00:00`,
      }
    },
    async onSubmit() {
      if (await this.$refs.refFormObserver.validate()) {
        showSpinner()
        patientService[this.id ? 'update' : 'create'](
          this.prepareRequest(cloneObject(this.form))
        )
          .then(res => {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Dados salvos com sucesso!',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (!this.id) {
              console.log(res)
              this.$router.push({ name: 'pacientes' })
            }
          })
          .finally(() => {
            hideSpinner()
          })
      } else {
        this.$toast({
          component: ToastificationContentVue,
          position: 'top-right',
          props: {
            title: 'Verifique os campos!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async getSchoolings() {
      this.schoolingOptions = await schoolingService.getAll()
    },
    async getMaritals() {
      this.maritalStatus = await maritalService.getAll()
    },
    async getPatientById() {
      const patient = await patientService.getById(this.id)
      patient.gender = this.genderOptions.find(
        gender => gender.id === patient.gender
      )
      this.form = patient
    },
  },
  computed: {
    dias() {
      return Object.keys(new Array(31).fill(0)).map(i => parseInt(i) + 1)
    },
    anos() {
      return Object.keys(new Array(150).fill(0)).map(i => parseInt(i) + 1852)
    },
  },
  mounted() {
    this.getSchoolings()
    this.getMaritals()
    console.log(this.id)
    if (this.id) {
      this.getPatientById()
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
